import React from "react";
import Box from "@mui/material/Box";
import { Divider, Grid, Paper } from "@mui/material";
import Modal from "@mui/material/Modal";
import Typography from "@mui/material/Typography";
import close from "../../../../assets/icons/close-icon.svg";
import facebook from "../../../../assets/icons/facebook-red1-icon.svg";
import instagram from "../../../../assets/icons/instagram-red1-icon.svg";
import twitter from "../../../../assets/icons/twitter-red1-icon.svg";
import { Scrollbars } from "react-custom-scrollbars-2";
import "../styles/Aboutus.css";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 800,

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p:2,
};

export default function ourTeamComponentModal(props) {
  const { onClose, open, data } = props;

  return (
    <div>
      <Modal
      className="team-laptop-popup"
        open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{zIndex: 10000}} >
        <Box className="team-popup" >
          <img onClick={onClose} style={{ float: "right", cursor: "pointer" }} src={close} height={20} width={20} alt="close"/>

          <Grid container >
            <Grid item lg={12} xs={12} sm={12}  sx={{ display: "flex",flexDirection: "row" }} >
              <Paper elevation={2}   className="team-popup-image" >
                <img
                  className="team-popup-image"
                  src={data.profile}
                  alt="profile"
                />
              </Paper>
              <Grid sx={{ marginLeft: "35px" }}>
                <Grid sx={{ marginTop: "10px" }}>
                  <Typography
                    className="team-popup-heading-font"
                    sx={{ textAlign: "left" }}
                  >
                    {data.profileName}
                  </Typography>
                </Grid>
                <Grid sx={{ marginTop: "10px" }}>
                  <Typography
                    className="team-popup-content-font"
                    sx={{ textAlign: "left", color: "#8B8B8B" }}
                  >
                    <b>{data.position}</b>
                  </Typography>
                </Grid>
                <Box  sx={{display: "flex", flexDirection: "row", marginTop: "25.5px" }}>
                  <Box className="team-popup-contact"  >
                    <img src={data.mobile}  style={{marginTop:"5px"}} height={20} width={20} alt="text" />
                    <Typography
                      sx={{ marginLeft: "10px" }}
                      className="team-popup-content-font"
                    >
                      +1 (888) 267-9990
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", flexDirection: "row" }}>
                    <img src={data.mail} style={{marginTop:"5px"}} height={20} width={20} alt="text" />
                    <Typography
                      sx={{ marginLeft: "10px" }}
                      className="team-popup-content-font"
                    >
                      info@nepta.us
                    </Typography>
                  </Box>
                </Box>
                <Box className="team-popup-social" >
                  <Box style={{display: "flex", flexDirection: "row", columnGap: "17px"}}>
                    <div>
                      <a
                        href={data.twitterlink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={twitter} alt="twitter" />
                      </a>
                    </div>
                    <div>
                      <a
                        href={data.facebooklink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img
                          style={{ fil: "red" }}
                          src={facebook}
                          alt="facebook"
                        />
                      </a>
                    </div>
                    <div>
                      <a
                        href={data.instagramlink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <img src={instagram} alt="instagram" />
                      </a>
                    </div>
                  </Box>
                </Box>
              </Grid>
            </Grid>
            <Grid lg={12} xs={12} sm={12}>
              <Divider sx={{ height: "4px" }} />
            </Grid>
            <Grid lg={12} xs={12} sm={12} sx={{ marginTop: "17px" }}>
              <Scrollbars className="team-popup-scroll" style={{height: "250px" }}>
                <Typography
                  sx={{ paddingRight: "20px", }}
                  className="team-popup-content-font"
                >
                  <span dangerouslySetInnerHTML={{ __html: data.content }} />
                </Typography>
              </Scrollbars>
            </Grid>
          </Grid>
        </Box>
      </Modal>

      <Modal
      className="team-mobile-popup"
       open={open}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{zIndex: 10000}} >
      <Box className="mobile-team-popup">
          <Grid>
            <img onClick={onClose} style={{ float: "right", cursor: "pointer" }} src={close} height={15} width={15} alt="close" />
            <Typography className="team-popup-heading-font">{data.profileName}</Typography>
            <Typography className="team-popup-content-font">{data.position}</Typography>
          </Grid>

          <Grid xs={12}>
            <Divider sx={{ height: "4px" }} />
          </Grid>
          <Grid container xs={12} sx={{ display: 'flex' }}>
            <Scrollbars style={{ height: "60vh" }}>

              <Grid container xs={12} sx={{ display: "flex", flexDirection: "row", justifyContent: 'center',marginTop:'15px' }} >
                <img
                  style={{ width: 200 }}
                  src={data.profile}
                  alt="profile"
                />

              </Grid>
              <Grid container xs={12} sx={{ display: "flex", flexDirection: "column", justifyContent: 'center', alignContent: 'space-evenly', marginTop:'15px' }}>
                <Grid sx={{display:"flex", alignContent:"center"}}>
                  <img src={data.mobile}  height={20} width={20} alt="text" />
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="team-popup-content-font"
                  >
                    +1 (888) 267-9990
                  </Typography>
                </Grid>

                <Grid sx={{ display: "flex", flexDirection: "row",marginTop:'15px',alignContent:"center" }}>
                  <img src={data.mail}  height={20} width={20} alt="text" />
                  <Typography
                    sx={{ marginLeft: "10px" }}
                    className="team-popup-content-font"
                  >
                    info@nepta.us
                  </Typography>
                </Grid>
              </Grid>

              <Grid container xs={12} sx={{ display: "flex", justifyContent: 'center', alignContent: 'space-evenly', marginTop:'15px' }}>
                <Grid style={{ display: "flex", flexDirection: "row", columnGap: "25px" }}>
                  <div>
                    <a
                      href={data.twitterlink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={twitter} alt="twitter" />
                    </a>
                  </div>
                  <div>
                    <a
                      href={data.facebooklink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img
                        style={{ fil: "red" }}
                        src={facebook}
                        alt="facebook"
                      />
                    </a>
                  </div>
                  <div>
                    <a
                      href={data.instagramlink}
                      rel="noreferrer"
                      target="_blank"
                    >
                      <img src={instagram} alt="instagram" />
                    </a>
                  </div>
                </Grid>
              </Grid>
              <Grid xs={12}>
                <Divider sx={{ height: "4px" }} />
              </Grid>
              <Grid sx={{marginTop:'15px', paddingRight:'15px'}}>
              <Typography
                 
                  className="team-popup-content-font"
                >
                 <span dangerouslySetInnerHTML={{ __html: data.content }} />
                </Typography>
              </Grid>
            </Scrollbars>
          </Grid>
        </Box>
      </Modal>
    </div>
  );
}
